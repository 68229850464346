<script>
import TablePage from '../../../../../../components/table_page';
import Form from '../form';
import configs from './data.js';
import request from '../../../../../../utils/request.js';

export default {
  extends: TablePage,
  data() {
    return {
      requestType: 'GET',
      requestUrl: '/cps-mall/v1/commodity/store',
      formName: 'Form',
      configs,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
      tablePage: {
        // 分页
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
      participantsOptions: [],
      distributionChannelOptions: [],
    };
  },
  components: {
    Form,
  },
  mounted() {
    this.getConfigList('cps_integral_goods_off_shelves_list').then((res) => {
      res.map((v) => {
        const rowData = v;
        if (!rowData.field) {
          rowData.className = 'formatter-image-mall';
        }
        return rowData;
      });
    });
  },
  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      if (!this.beforeModalClick({ val, row })) return;
      this.formConfig = {};
      if (val.code === 'add') {
        this.formName = 'Form';
        this.formConfig = {
          code: 'add',
        };
        this.modalConfig.title = '新增';
        this.openFull();
      }
      if (val.code === 'off_shelf') {
        if (this.selection.length === 0) {
          this.$message({
            type: 'info',
            message: '请至少勾选一个上架商品',
          });
          return;
        }
        // console.log(this.selection);
        let ids = '';
        this.selection.forEach((item, index) => {
          if (index < this.selection.length - 1) {
            ids += `ids=${item}&`;
          } else {
            ids += `ids=${item}`;
          }
        });
        request.patch(`/cps-mall/v1/commodity/store/patch?${ids}`).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.getList();
          }
        });
      }
      if (val.code === 'on_shelf') {
        if (this.selection.length === 0) {
          this.$message({
            type: 'info',
            message: '请至少勾选一个已下架商品',
          });
          return;
        }
        const chosedData = this.tableData.filter((item) => this.selection.includes(item.id));
        const status = chosedData.map((item) => item.status);
        const flag = status.some((item) => item === 1);
        if (flag) {
          this.$message({
            type: 'info',
            message: '您所选择的商品中包含已上架商品，请重新选择！',
          });
          return;
        }
        this.formConfig = { data: chosedData, code: 'edit' };
        this.formName = 'Form';
        this.modalConfig.title = '上架';
        this.openFull();
      }
    },
    // 设置图片
    setColumn(col) {
      const rowData = col;
      rowData.className = 'formatter-image-mall';
      if (rowData.field === 'pictureForList') {
        rowData.type = 'html';
        rowData.formatter = ({ cellValue }) => `<img src="${cellValue}" />`;
      }
      if (rowData.field === 'validTime') {
        rowData.formatter = ({ row }) => `${row.startTime}-${row.endTime}`;
      }
      return rowData;
    },
    // 获取列表数据
    getList() {
      const { searchFormData, tablePage } = this;
      const params = {
        ...this.params,
        ...searchFormData,
      };
      params.page = this.tablePage.currentPage;
      params.size = this.tablePage.pageSize;
      request
        .get(
          this.requestUrl,
          {
            ...params,
          },
          { headers: { functionCode: this.headerFunctionCode } },
        )
        .then(async (res) => {
          if (res.success) {
            await this.getOptions();
            this.tableLoading = false;
            if (res.result.records) {
              this.tableData = res.result.records.map((item) => {
                let participantData = '';
                let channelData = '';
                let agreementCodeData = '';
                if (item.participantCodeArray) {
                  const participantCodeArray = this.participantsOptions.filter((i) => item.participantCodeArray.includes(i.dictCode));
                  participantCodeArray.forEach((j, ind) => {
                    if (ind < participantCodeArray.length - 1) {
                      participantData += `${j.dictValue},`;
                    } else {
                      participantData += `${j.dictValue}`;
                    }
                  });
                }
                if (item.channelCodeArray) {
                  const channelCodeArray = this.distributionChannelOptions.filter((i) => item.channelCodeArray.includes(i.dictCode));
                  channelCodeArray.forEach((j, ind) => {
                    if (ind < channelCodeArray.length - 1) {
                      channelData += `${j.dictValue},`;
                    } else {
                      channelData += `${j.dictValue}`;
                    }
                  });
                }
                if (item.agreementCodeArray) {
                  item.agreementCodeArray.forEach((j, ind) => {
                    if (ind < item.agreementCodeArray.length - 1) {
                      agreementCodeData += `${j.name},`;
                    } else {
                      agreementCodeData += `${j.name}`;
                    }
                  });
                }
                return {
                  ...item,
                  participantData,
                  channelData,
                  agreementCodeData,
                };
              });
              this.tablePage.totalResult = res.result.total;
            }
            this.afterGetList();
            this.setSelectData();
          }
        });
    },
    async getOptions() {
      const res = await request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'ParticipatorType',
      });
      this.participantsOptions = res.result;
      const res1 = await request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'channel',
      });
      this.distributionChannelOptions = res1.result;
    },
  },
  created() {
    // this.getConfigList('cps_integral_goods_off_shelves_list');
  },
};
</script>
