var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-table" },
    [
      _vm.formConfig.code === "add"
        ? _c(
            "div",
            { staticClass: "padBot10" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-circle-plus-outline",
                  },
                  on: { click: _vm.open },
                },
                [_vm._v("添加积分商品")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: {
            border: "",
            "show-overflow": "",
            data: _vm.productList,
            resizable: true,
          },
        },
        [
          _c("vxe-table-column", {
            attrs: {
              field: "code",
              title: "积分商品编码",
              "min-width": "110px",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "className",
              title: "积分商品层级",
              "min-width": "110px",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "typeName",
              title: "积分商品类型",
              "min-width": "110px",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "pictureForList",
              title: "积分商品图片",
              "class-name": "formatter-image-mall",
              "min-width": "100px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-image", {
                      attrs: {
                        src: row.pictureForList,
                        "preview-src-list": [row.pictureForList],
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "name",
              title: "积分商品名称",
              "min-width": "110px",
            },
          }),
          _c("vxe-table-column", {
            attrs: { field: "price", title: "积分", "min-width": "110px" },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "totalOnSale",
              title: "上架数量",
              "cell-render": {
                props: { min: 1 },
                validate: [
                  {
                    required: true,
                    message: "请输入上架数量",
                    trigger: "blur",
                  },
                ],
              },
              "min-width": "150px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("vxe-input", {
                      attrs: {
                        placeholder: "请输入上架数量",
                        size: "mini",
                        type: "number",
                      },
                      model: {
                        value: row.totalOnSale,
                        callback: function ($$v) {
                          _vm.$set(row, "totalOnSale", $$v)
                        },
                        expression: "row.totalOnSale",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "validTime",
              title: "上下架时间",
              "min-width": "400px",
              "cell-render": {
                props: {},
                validate: [
                  {
                    required: true,
                    message: "请输入上下架时间",
                    trigger: "blur",
                  },
                ],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetimerange",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        format: "yyyy-MM-dd HH:mm:ss",
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        "default-time": ["00:00:00", "23:59:59"],
                      },
                      model: {
                        value: row.validTime,
                        callback: function ($$v) {
                          _vm.$set(row, "validTime", $$v)
                        },
                        expression: "row.validTime",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "channelConditions",
              title: "渠道限制",
              "cell-render": {
                props: {},
              },
              "min-width": "180px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          multiple: "",
                          clearable: "",
                          "collapse-tags": "",
                        },
                        model: {
                          value: row.channelConditions,
                          callback: function ($$v) {
                            _vm.$set(row, "channelConditions", $$v)
                          },
                          expression: "row.channelConditions",
                        },
                      },
                      _vm._l(_vm.distributionChannelOptions, function (item) {
                        return _c("el-option", {
                          key: item.dictCode,
                          attrs: {
                            label: item.dictValue,
                            value: item.dictCode,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "agreementConditions",
              title: "协议模板限制",
              "cell-render": {
                props: {},
                validate: [
                  {
                    required: true,
                    message: "请选择协议模板",
                    trigger: "blur",
                  },
                ],
              },
              "min-width": "280px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择",
                                  multiple: "",
                                  clearable: "",
                                  "collapse-tags": "",
                                },
                                model: {
                                  value: row.agreementConditions,
                                  callback: function ($$v) {
                                    _vm.$set(row, "agreementConditions", $$v)
                                  },
                                  expression: "row.agreementConditions",
                                },
                              },
                              _vm._l(
                                row.agreementConditionsOptions || [],
                                function (item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.name,
                                      value: item.code,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { staticClass: "flex-end", attrs: { span: 4 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openTemplateModal(row)
                                  },
                                },
                              },
                              [_vm._v("添加")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "participantConditions",
              title: "面向参与者",
              "cell-render": {
                props: {},
              },
              "min-width": "180px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          multiple: "",
                          clearable: "",
                          "collapse-tags": "",
                        },
                        model: {
                          value: row.participantConditions,
                          callback: function ($$v) {
                            _vm.$set(row, "participantConditions", $$v)
                          },
                          expression: "row.participantConditions",
                        },
                      },
                      _vm._l(_vm.participantsOptions, function (item) {
                        return _c("el-option", {
                          key: item.dictCode,
                          attrs: {
                            label: item.dictValue,
                            value: item.dictCode,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              title: "操作",
              width: "60",
              fixed: "right",
              align: "center",
              "class-name": "formatter-image-mall",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { staticClass: "setting-btn" },
                      [
                        _c(
                          "Popconfirm",
                          {
                            attrs: {
                              title: "此操作将该数据从列表中移除？",
                              "confirm-button-type": "text",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.removeRow(row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-delete",
                                  staticStyle: { color: "#f5222d" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.callBackFn },
      }),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                loading: _vm.$store.state.buttonApiIsLoading,
                icon: "el-icon-check",
              },
              on: {
                click: function ($event) {
                  return _vm.submit(1)
                },
              },
            },
            [_vm._v("提交 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", icon: "el-icon-close" },
              on: {
                click: function ($event) {
                  return _vm.$emit("onClose")
                },
              },
            },
            [_vm._v("关闭 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }