  <template>
  <div class="custom-table">
    <div v-if="formConfig.code==='add'" class="padBot10">
      <el-button type="primary" icon="el-icon-circle-plus-outline" @click="open">添加积分商品</el-button>
    </div>
    <vxe-table border show-overflow ref="xTable" :data="productList" :resizable="true">
      <vxe-table-column field="code" title="积分商品编码"  min-width="110px"></vxe-table-column>
      <vxe-table-column field="className" title="积分商品层级" min-width="110px"></vxe-table-column>
      <vxe-table-column field="typeName" title="积分商品类型" min-width="110px"></vxe-table-column>
      <vxe-table-column field="pictureForList" title="积分商品图片" class-name="formatter-image-mall" min-width="100px">
        <template #default="{row}">
          <el-image style="" :src="row.pictureForList" :preview-src-list="[row.pictureForList]">
          </el-image>
        </template>
      </vxe-table-column>
      <vxe-table-column field="name" title="积分商品名称" min-width="110px"></vxe-table-column>
      <vxe-table-column field="price" title="积分" min-width="110px"></vxe-table-column>
      <vxe-table-column field="totalOnSale" title="上架数量" :cell-render="{
            props: { min: 1 },
            validate:[
              { required: true, message: '请输入上架数量', trigger: 'blur' },
          ],
          }" min-width="150px">
        <template #default="{row}">
          <vxe-input v-model="row.totalOnSale" placeholder="请输入上架数量" size="mini" type="number"></vxe-input>
        </template>
      </vxe-table-column>
      <vxe-table-column field="validTime" title="上下架时间" min-width="400px" :cell-render="{
            props: {},
            validate:[
              { required: true, message: '请输入上下架时间', trigger: 'blur' },
          ],
          }">
        <template #default="{row}">
          <el-date-picker v-model="row.validTime" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </template>
      </vxe-table-column>
      <vxe-table-column field="channelConditions" title="渠道限制" :cell-render="{
            props: {}
          }" min-width="180px">
        <template #default="{row}">
          <el-select v-model="row.channelConditions" placeholder="请选择" multiple clearable collapse-tags>
            <el-option v-for="item in distributionChannelOptions" :key="item.dictCode" :label="item.dictValue" :value="item.dictCode">
            </el-option>
          </el-select>
        </template>
      </vxe-table-column>
      <vxe-table-column field="agreementConditions" title="协议模板限制" :cell-render="{
            props: {},
            validate:[
              { required: true, message: '请选择协议模板', trigger: 'blur' }]
          }" min-width="280px">
        <template #default="{row}">
          <el-row>
            <el-col :span="20">
              <el-select v-model="row.agreementConditions" placeholder="请选择" multiple clearable collapse-tags>
                <el-option v-for="item in (row.agreementConditionsOptions||[])" :key="item.code" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="4" class="flex-end">
              <el-button @click="openTemplateModal(row)" type="primary">添加</el-button>
            </el-col>
          </el-row>
        </template>
      </vxe-table-column>
      <vxe-table-column field="participantConditions" title="面向参与者" :cell-render="{
            props: {}
          }" min-width="180px">
        <template #default="{row}">
          <el-select v-model="row.participantConditions" placeholder="请选择" multiple clearable collapse-tags>
            <el-option v-for="item in participantsOptions" :key="item.dictCode" :label="item.dictValue" :value="item.dictCode">
            </el-option>
          </el-select>
        </template>

      </vxe-table-column>
      <vxe-table-column title="操作" width="60" fixed="right" align="center" class-name="formatter-image-mall">
        <template v-slot="{ row }">
          <div class="setting-btn">
            <Popconfirm title="此操作将该数据从列表中移除？" confirm-button-type="text" @confirm="removeRow(row)">
              <el-button slot="reference" type="text">
                <i class="el-icon-delete" style="color: #f5222d"></i>
              </el-button>
            </Popconfirm>
          </div>
        </template>
      </vxe-table-column>
    </vxe-table>
    <SelectConfig ref="selectConfig" @onGetSelect="callBackFn" />
    <div class="dialog-footer">
      <el-button type="primary" @click="submit(1)" size="small" :loading="$store.state.buttonApiIsLoading" icon="el-icon-check">提交
      </el-button>
      <el-button type="danger" @click="$emit('onClose')" size="small" icon="el-icon-close">关闭
      </el-button>
    </div>
  </div>
</template>

<script>
import { Popconfirm } from 'element-ui';
import SelectConfig from '../../../../../../components/select_org_cus_ter/select_config';
import request from '../../../../../../utils/request.js';
import Form from '../../../../../../components/form';

export default {
  extends: Form,
  name: 'integralGoodsOffShelves',
  components: {
    Popconfirm,
    SelectConfig,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(v) {
        // console.log(v);
        // if (v) {
        //   this.productList = v;
        // }
      },
    },
  },
  data() {
    return {
      productList: [],
      participantsOptions: [], // 参与者
      distributionChannelOptions: [], // 渠道
      // agreementConditionsOptions: [],
      formSelectConfig: {
        setColumn: true,
      },
      modelType: 'product',
      agreementIndex: null,
    };
  },
  created() {
    // 获取数据字典
    this.getOptions();
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 积分商品选择弹框
    open() {
      this.modelType = 'product';
      const type = 'checkbox';
      const params = {
        functionCode: 'shoppig_product_table',
        data: this.value,
        idKey: 'id',
        requestType: 'GET',
        requestUrl: '/cps-mall/v1/commodity/findSaleByCondition',
        setColumn: this.setColumn,
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 协议模板选择弹框
    openTemplateModal(row) {
      this.agreementIndex = this.$refs.xTable.getRowIndex(row);
      this.modelType = 'agreement';
      const codes = this.value.map((item) => item.materialCode);
      const type = 'checkbox';
      const params = {
        functionCode: 'agreement_template_table',
        data: this.value,
        idKey: 'id',
        requestType: 'GET',
        requestUrl: '/cps-mall/v1/commodity/cps/agreement',

      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 选择回调
    callBackFn(row) {
      // console.log(this.getReset);
      if (this.modelType === 'product') {
        const selectData = [];
        if (row) {
          for (const i of row) {
            selectData.push({
              ...i,
            });
          }
        }
        this.productList = this.getReset(selectData, this.productList, 'code');
        // this.productList = selectData;
        // this.formConfig
      } else {
        const selectData = [];
        if (row) {
          for (const i of row) {
            selectData.push({
              ...i,
              count: 0,
              ratio: 0,
            });
          }
        }
        const seletList = selectData.map((item) => ({
          code: item.code,
          name: item.name,
        }));

        this.productList[this.agreementIndex].agreementConditionsOptions = this.getReset(seletList, (this.productList[this.agreementIndex].agreementConditionsOptions || []), 'code');
        (this.productList[this.agreementIndex].agreementConditions || []).push(...seletList.map((item) => item.code));
        this.productList[this.agreementIndex].agreementConditions = Array.from(new Set(this.productList[this.agreementIndex].agreementConditions));
      }
    },
    // 删除行数据
    removeRow(row) {
      const index = this.$refs.xTable.getRowIndex(row);
      const list = [];
      this.productList.splice(index, 1);
      this.$emit('input', this.productList);
    },
    submit() {
      const params = this.productList.map((item) => ({
        goodsCode: item.code,
        quantity: item.totalOnSale,
        startTime:
          item.validTime && item.validTime.length > 0 ? item.validTime[0] : '',
        endTime:
          item.validTime && item.validTime.length > 0 ? item.validTime[1] : '',
        channelConditions: item.channelConditions,
        agreementConditions: (item.agreementConditionsOptions || []).filter((i) => item.agreementConditions.includes(i.code)),
        participantConditions: item.participantConditions,
        id: this.formConfig.code === 'add' ? null : item.id,
      }));
      request.post('/cps-mall/v1/commodity/store/patch', params).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
    // 获取数据字典数据
    async getOptions() {
      const res = await request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'ParticipatorType',
      });
      this.participantsOptions = res.result;
      const res1 = await request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'channel',
      });
      this.distributionChannelOptions = res1.result;
    },
    setColumn(col) {
      const rowData = col;
      rowData.className = 'formatter-image-mall';
      if (rowData.field === 'pictureForList') {
        rowData.type = 'html';
        rowData.formatter = ({ cellValue }) => `<img src="${cellValue}" />`;
      }
      if (rowData.field === 'classId') {
        // console.log(rowData);
        rowData.apiUrl = '/cps-mall/v1/commodity/classification/findList';
        rowData.methods = 'get';
        rowData.optionsKey = {
          value: 'id',
          label: 'name',
        };
        rowData.formatter = ({ row }) => row.className;
      }

      return rowData;
    },
    // 获取数据
    getData() {
      if (this.formConfig.code === 'edit') {
        this.productList = this.formConfig.data.map((item) => {
          if (item.startTime) {
            return {
              ...item,
              validTime: [item.startTime, item.endTime],
              channelConditions: item.channelCodeArray,
              agreementConditionsOptions: item.agreementCodeArray,
              agreementConditions: item.agreementCodeArray.map((i) => i.code),
              participantConditions: item.participantCodeArray,

            };
          }
          return item;
        });
      }
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = JSON.parse(JSON.stringify(list2));
      list1.forEach((v1) => {
        let notRepeat = true;
        const rowData = v1;
        for (const v2 in list2) {
          if (v1[key] === list2[v2][key]) {
            notRepeat = true;
            return false;
          }
        }
        if (notRepeat) {
          list.push(rowData);
        }
        return rowData;
      });
      return list;
    },
  },
};
</script>

<style lang="less" scoped>
.custom-table {
  padding-left: 30px;
  box-sizing: border-box;
}
/deep/ .formatter-image-mall {
  .vxe-cell,
  .vxe-cell--html {
    height: 80px !important;
    max-height: 80px !important;
    display: flex;
    // justify-content: center;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
      object-fit: contain;
    }
  }
}

.padBot10 {
  padding: 0 0 5px 0;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.dialog-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  z-index: 99;
  background-color: white;
  border-top: 1px solid rgb(228, 228, 228);
}
.fixed-right--wrapper{
  height:110px;
}
.minh110{
  min-height: 110px !important;
}
</style>
